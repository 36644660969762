//
// avatar.scss (avatar thumbnail sizes)
//

.avatar-size-200-200 {
  height: 200px;
  width: 200px;
}

.avatar-xs-custom {
  height: 2.5rem;
  width: 2.5rem;
}

.avatar-fixed-custom {
  height: 2.5rem;
  width: 70px;
}

.avatar-xs {
  height: 1.5rem;
  width: 1.5rem;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar-lg {
  height: 6rem;
  width: 6rem;
}

.avatar-xl {
  height: 7.5rem;
  width: 7.5rem;
  align-items: center;
  justify-content: center;
}

.avatar-title {
  align-items: center;
  background-color: $primary;
  color: $white;
  display: flex;
  font-weight: $font-weight-semibold;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.avatar-title1-custom {
  height: 2rem;
  width: 3.5rem;
  margin-top: -20%;
  margin-left: 12%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnail-custom {
  display: block;
  padding: 1px;
  padding-top: 1px;
  margin: 0px;
  height: 100%;
  line-height: 1.42857;
  background-color: #fff;
  // border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
}